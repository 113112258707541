<template>

  <item-text title="Rēķina nr." :text="item.uuid" />

  <item-text title="Rēķina datums" :text="item.invoice_date" />

  <div>
    <Badge v-if="item.payment_date" :text="'Apmaksāts ' + item.payment_date"
           :className="`bg-green-600 text-white  mr-2 mb-2 md:mb-0 md:mt-5 text-xs`"
    />
  </div>


  <div class="w-full flex flex-wrap my-2 items-start">
    <item-text title="Klients">
      <template v-slot:content>
        <router-link :to="`/customers/${item.customer_id}`" class="mt-2 flex items-center text-md font-semibold text-gray-900 dark:text-gray-300 sm:mt-0 hover:text-main4 dark:hover:text-main4">
          {{item.customer}}
        </router-link>
      </template>
    </item-text>
  </div>

  <div class="w-full flex flex-wrap my-2 items-start">
    <item-text title="Pozīcijas" :text="item.order_items_count" />
    <item-text title="Kopā EUR" :text="item.total" />
    <item-text title="PVN EUR" :text="item.vat" />
    <item-text title="Summa EUR" :text="item.total_with_vat" />
    <item-text title="Reversais PVN" :text="item.reverse_vat ? 'Jā' : 'Nē'" />


    <template v-if="item.notes">
      <div class="w-full mt-5">
        <item-text title="Piezīmes" :text="item.notes" />
      </div>
    </template>
  </div>

  <div class="w-full flex flex-wrap items-start">
    <template v-if="item.shipping_cost">
      <item-text title="Piegādes izmaksas" :text="item.shipping_cost" />
    </template>
    <template v-if="item.shipping_price">
      <item-text title="Piegādes cena klientam" :text="item.shipping_price" />
    </template>
    <template v-if="item.shipping_difference || item.shipping_difference === 0">
      <item-text title="Piegādes starpība EUR" :text="item.shipping_difference" />
    </template>
  </div>

  <div class="w-full flex flex-wrap my-2 items-start">
      <item-text title="Kopā" :text="item.total" />
      <item-text title="Iepirkuma cena" :text="item.total_purchase" />
      <item-text title="Starpība" :text="item.total_difference" />
      <item-text title="Kopā starpība" :text="item.total_difference_with_shipping" />
  </div>

</template>

<script>
import ItemText from "@/components/Components/ItemText"
import Badge from "../../Components/Badge.vue";

export default {
  name: "ShowInvoiceDetails",
  components: {
    ItemText,
    Badge
  },
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
}
</script>

<style>

</style>